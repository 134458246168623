import {
  BOOKING_PROVIDER_KVV,
  BOOKING_PROVIDER_NEXTBIKE,
  BOOKING_PROVIDER_STADTMOBIL,
  BOOKING_PROVIDER_VOI,
  BOOKING_PROVIDER_IOKI,
  BOOKING_TYPE_ACTIVE,
  BOOKING_TYPE_INACTIVE,
  BOOKING_TYPE_RESERVED,
  BOOKING_TYPE_FAILED,
  BOOKING_TYPE_CLEARED,
  BOOKING_TYPE_CANCELED,
  BOOKING_TYPE_PAUSED,
  BOOKING_TYPE_FINISHED,
  BOOKING_TYPE_NONE,
  BOOKING_TYPE_READY,
  BOOKING_TYPE_WAITING,
  BOOKING_PROVIDER_TGO,
  CLIENT_ID,
} from 'util/constants'

// Returns webfont icon of a booking provider if available
export const getBookingProviderIcon = (bookingProvider) => {
  switch (bookingProvider) {
    case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID): {
      return 'regio_icon-kvv'
    }
    case getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID): {
      return 'regio_icon-nextbike'
    }
    case getBookingProvider(BOOKING_PROVIDER_STADTMOBIL, CLIENT_ID): {
      return 'regio_icon-stadtmobil'
    }
    case getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID): {
      return 'mobility_icon_voi'
    }
    case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
      return 'regio_icon-myshuttle'
    }
    case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
      return 'regio_icon-tgo'
    }
    default: {
      return null
    }
  }
}

// Returns color of booking provider or default color if not available
export const getBookingProviderColor = (bookingProvider) => {
  switch (bookingProvider) {
    case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID): {
      return '#b1151b'
    }
    case getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID): {
      return getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID) === 'NextBikeOrtenauMobil' ? '#90b627' : '#fc0023'
    }
    case getBookingProvider(BOOKING_PROVIDER_STADTMOBIL, CLIENT_ID): {
      return '#007dcb'
    }
    case getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID): {
      return '#f46c63'
    }
    case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
      return '#000000'
    }
    case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
      return '#5eb130'
    }
    default: {
      return 'gray'
    }
  }
}

// Returns the text of the booking status
export const formatBookingState = (bookingState) => {
  switch (bookingState) {
    case BOOKING_TYPE_FINISHED:
      return 'Beendet'
    case BOOKING_TYPE_ACTIVE:
      return 'Aktiv'
    case BOOKING_TYPE_FAILED:
      return 'Fehlgeschlagen'
    case BOOKING_TYPE_PAUSED:
      return 'Geparkt'
    case BOOKING_TYPE_RESERVED:
      return 'Reserviert'
    case BOOKING_TYPE_CANCELED:
      return 'Storniert'
    case BOOKING_TYPE_CLEARED:
      return 'Beendet'
    case BOOKING_TYPE_NONE:
      return 'Keiner'
    case BOOKING_TYPE_READY:
      return 'Bereit'
    case BOOKING_TYPE_WAITING:
      return 'Wartet'
    default:
      return bookingState
  }
}

// Returns the booking state of the nextbike, stadtmobil and voi bookings
export const getActiveBookingState = (rentalState, bookingType) => {
  // The rentalState is only interesting if the booking is active
  if (bookingType === BOOKING_TYPE_ACTIVE) {
    if (rentalState === BOOKING_TYPE_INACTIVE) {
      // If booking is active and rental inactive the bike is reserved
      return BOOKING_TYPE_RESERVED
    } else {
      // Return rentalState if booking is active so we get the state of the rental
      return rentalState
    }
  } else {
    // Return bookingType if booking is not active to get the state of the booking
    return bookingType
  }
}

export const groupPrice = (bookingItems) => {
  let addedPrice = 0
  for (const booking in bookingItems) {
    addedPrice += (bookingItems[booking].Amount)
  }
  return (addedPrice / 100).toString()
}

export const getDisplayNameForMobilityProvider = (mobilityProvider) => {
  switch (mobilityProvider) {
    case getBookingProvider(BOOKING_PROVIDER_KVV, CLIENT_ID): {
      return 'KVV'
    }
    case getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID): {
      return getBookingProvider(BOOKING_PROVIDER_NEXTBIKE, CLIENT_ID) === 'NextBikeOrtenauMobil' ? 'nextbike' : 'KVV.nextbike'
    }
    case getBookingProvider(BOOKING_PROVIDER_STADTMOBIL, CLIENT_ID): {
      return 'Stadtmobil'
    }
    case getBookingProvider(BOOKING_PROVIDER_VOI, CLIENT_ID): {
      return 'Voi'
    }
    case getBookingProvider(BOOKING_PROVIDER_IOKI, CLIENT_ID): {
      return 'MyShuttle'
    }
    case getBookingProvider(BOOKING_PROVIDER_TGO, CLIENT_ID): {
      return 'TGO'
    }
    default: {
      return mobilityProvider
    }
  }
}

export const getBookingProvider = (bookingProvider, clientId) => {
  if (clientId === 'ortenaumobil') {
    switch (bookingProvider) {
      case BOOKING_PROVIDER_KVV: {
        return 'KVVOrtenauMobil'
      }
      case BOOKING_PROVIDER_TGO: {
        return 'TGO'
      }
      case BOOKING_PROVIDER_NEXTBIKE: {
        return 'NextBikeOrtenauMobil'
      }
      case BOOKING_PROVIDER_STADTMOBIL: {
        return 'StadtMobilOrtenauMobil'
      }
      case BOOKING_PROVIDER_VOI: {
        return 'VOIOrtenauMobil'
      }
      case BOOKING_PROVIDER_IOKI: {
        return 'IOKIOrteanuMobil'
      }
      default:
        return null
    }
  } else {
    switch (bookingProvider) {
      case BOOKING_PROVIDER_KVV: {
        return 'KVV'
      }
      case BOOKING_PROVIDER_TGO: {
        return 'TGORegiomove'
      }
      case BOOKING_PROVIDER_NEXTBIKE: {
        return 'NextBike'
      }
      case BOOKING_PROVIDER_STADTMOBIL: {
        return 'StadtMobil'
      }
      case BOOKING_PROVIDER_VOI: {
        return 'VOI'
      }
      case BOOKING_PROVIDER_IOKI: {
        return 'IOKI'
      }
      default:
        return null
    }
  }
}

export const getMobilityProviderNumberForBookingProvider = (mobilityProviderName) => {
  switch (mobilityProviderName) {
    case 'KVV':
      return 1
    case 'NextBike':
      return 2
    case 'StadtMobil':
      return 3
    case 'VOI':
      return 4
    case 'IOKI':
      return 5
    case 'TGO':
      return 6
    case 'NextBikeOrtenauMobil':
      return 7
    case 'KVVOrtenauMobil':
      return 8
    case 'StadtMobilOrtenauMobil':
      return 9
    case 'VOIOrtenauMobil':
      return 10
    case 'IOKIOrteanuMobil':
      return 11
    case 'TGORegiomove':
      return 12
    default:
      return null
  }
}
