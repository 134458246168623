import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Grid,
  Divider,
  makeStyles,
  withStyles,
  Link,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component='div' variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

/**
 *  this dialog manages its internal "open" state but has an imperative handle to enable parent components to open it
 *  see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
 */
const SupportContactDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true)
    },
  }))

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='title' open={open} maxWidth='md' fullWidth>
      <DialogTitle id='title' onClose={handleClose}>
        <Typography component='div' variant='h4' className={classes.mb2}>Support Kontakte</Typography>
        <Typography variant='subtitle2' color='textSecondary'>Hier finden Sie die Support-Kontaktdaten der regiomove Partner zur Herausgabe an den Kunden.</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container item className={classes.text} spacing={4}>
          <Grid item xs>
            <Typography component='div' color='textSecondary' variant='h6'>Mobilitätsdienste</Typography>
            <Divider className={classes.mb2} />

            <Grid container item className={classes.mb2}>
              <Typography className={classes.subtitle} variant='button'>Nextbike</Typography>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>Telefon</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color='textSecondary' variant='subtitle2'>+49 (0) 30 69205046</Typography>
                </Grid>
              </Grid>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>E-Mail</Typography>
                </Grid>
                <Grid item xs>
                  <Link className={classes.link} variant='subtitle2' href='mailto:kundenservice@nextbike.de'>kundenservice@nextbike.de</Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className={classes.mb2}>
              <Typography className={classes.subtitle} variant='button'>Stadtmobil</Typography>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>Telefon</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color='textSecondary' variant='subtitle2'>+49 (0) 721 9119110</Typography>
                </Grid>
              </Grid>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>E-Mail</Typography>
                </Grid>
                <Grid item xs>
                  <Link className={classes.link} variant='subtitle2' href='mailto:info@karlsruhe.stadtmobil.de'>info@karlsruhe.stadtmobil.de</Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className={classes.mb2}>
              <Typography className={classes.subtitle} variant='button'>Voi</Typography>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>E-Mail</Typography>
                </Grid>
                <Grid item xs>
                  <Link className={classes.link} variant='subtitle2' href='mailto:support@voiapp.io'>support@voiapp.io</Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography component='div' color='textSecondary' variant='h6'>Zahlungsdienstleister</Typography>
            <Divider className={classes.mb2} />
            <Grid container item>
              <Typography className={classes.subtitle} variant='button'>Logpay</Typography>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography component='span' color='textSecondary' variant='subtitle2'>Telefon</Typography>
                </Grid>
                <Grid item xs>
                  <Typography component='span' color='textSecondary' variant='subtitle2'>+49 (0) 6196 8012 702</Typography>
                </Grid>
              </Grid>
              <Grid container item direction='row' justify='flex-start' spacing={1} wrap='nowrap'>
                <Grid item xs={3}>
                  <Typography color='textSecondary' variant='subtitle2'>E-Mail</Typography>
                </Grid>
                <Grid item xs>
                  <Link className={classes.link} variant='subtitle2' href='mailto:kundencenter@logpay.de '>kundencenter@logpay.de</Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider variant='middle' />
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const useStyles = makeStyles(theme => ({
  centerIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
  },
  text: {
    justifyContent: 'space-between',
    alignContent: 'space-evenly',
    wrap: 'nowrap',
  },
  link: {
    underline: 'none',
    color: 'primary',
  },
  margin: {
    margin: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default SupportContactDialog
